import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import styles from './hero.module.scss';

import illustration from './images/illustration.svg';
import shape from './images/shape.svg';

const cx = classNames.bind(styles);

const Hero = ({ title, description }) => (
  <section className={cx('wrapper')}>
    <div className={cx('container', 'inner')}>
      <div className={cx('left')}>
        <Heading className={cx('title')} size="xxl">
          {title}
        </Heading>
        {description && <p className={cx('description')}>{description}</p>}
      </div>
      <div className={cx('right')} arria-hidden>
        <img className={cx('shape')} src={shape} width={1028} height={873} loading="eager" alt="" />
        <img
          className={cx('illustration')}
          src={illustration}
          width={450}
          height={396}
          loading="eager"
          alt=""
        />
      </div>
    </div>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Hero.defaultProps = {
  description: null,
};

export default Hero;
