import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Heading from 'components/shared/heading';
import Categories from 'components/shared/categories';
import Link from 'components/shared/link';
import Button from 'components/shared/button';

import { BUTTON_SIZES, BUTTON_THEMES } from 'constants/button';
import styles from './items.module.scss';

const cx = classNames.bind(styles);

const Items = ({ items, categories, pageRootPath, activeCategoryId, labelCategoryAll }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      {!!categories.length && (
        <Categories
          className={cx('categories')}
          categories={categories}
          pageRootPath={pageRootPath}
          activeCategoryId={activeCategoryId}
          labelCategoryAll={labelCategoryAll}
          labelMobileCategoryAll={labelCategoryAll}
        />
      )}
      <div
        className={cx('items', {
          'without-categories': !categories.length,
        })}
      >
        {items.map(({ title, uri, acf: { description, image } }, index) => (
          <Link className={cx('item')} key={index} to={uri}>
            <div className={cx('image-wrapper')}>
              <GatsbyImage image={getImage(image.localFile.childImageSharp)} alt={image.altText} />
            </div>
            <div className={cx('content')}>
              <div>
                <Heading className={cx('title')} tag="h3" size="lg">
                  {title}
                </Heading>
                {description && <p className={cx('description')}>{description}</p>}
              </div>
              <div className={cx('footer')}>
                <Button
                  className={cx('button')}
                  size={BUTTON_SIZES.SM}
                  theme={BUTTON_THEMES.ACCENT_PRIMARY}
                >
                  Download PDF
                </Button>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  </section>
);

Items.defaultProps = {
  activeCategoryId: '',
  labelCategoryAll: 'All White Papers',
};

Items.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      acf: PropTypes.shape({
        link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.string,
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
              }),
            }),
          }),
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageRootPath: PropTypes.string.isRequired,
  activeCategoryId: PropTypes.string,
  labelCategoryAll: PropTypes.string,
};

export default Items;
